/* src/LandingPage.css */
html,
body {
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    overflow: hidden;
    font-family: 'Montserrat', sans-serif;
    background-color: black;
}

.landing-page {
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: none;
    position: relative;
}

.circle {
    position: absolute;
    width: 80px;
    height: 80px;
    background-color: green;
    border-radius: 50%;
    pointer-events: none;
    transition: transform 0.2s;
}